
import { defineComponent, onMounted, ref } from "vue";
import ApiService from "@/core/services/ApiService";
//import  Master from "@/models/MasterModel"
import * as Mast from "@/models/MasterModel";
import { useRouter } from "vue-router";
import * as Dash from "@/models/DashboardModel";
import alarmEditForm from "@/components/AlarmEditForm.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { AnyObject } from "yup/lib/types";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "alarm-table",
  components: {
    alarmEditForm,
  },
  props: {
    widgetClasses: String,
    chartId: String,
    chartTypeId: String,
  },
  setup(props) {
    const editform1 = ref<InstanceType<typeof alarmEditForm>>();
    const router = useRouter();
    const { t } = useI18n();
   
    var alarmToEdit = ref<number>(0);
    const alarms = ref<Mast.Alarm[]>([] as Mast.Alarm[]);
    const userAdresses = ref<Mast.Address[]>([] as Mast.Address[]);
    const userSlaves = ref<Mast.Slave[]>([] as Mast.Slave[]);
    const initAlarmEditForm = ref<number>(0);

    onMounted(() => {
      getSlaves();
      getAlarms();
    });

    const getAlarms = () => {
      const postData = {
        //todo geert  tijdelijke params
        searchString: "",
        parentIds: null,
        pageSize: 1000,
        pageNumber: "",
      } as any;
      ApiService.post("/device/alarms", postData)
        .then(({ data }) => {
          console.log("getAlarms response data:"); //todo geert
          console.log({ ...data }); //todo geert
          alarms.value = data.results;
        })
        .catch(({ response }) => {
          console.log("Fout getAlarms");
        });
    };

    const getSlaves = () => {
      const postData = {
        //todo geert  tijdelijke params
        pageSize: 10000,
      } as any;

      ApiService.post("/device/slaves", postData)
        .then(({ data }) => {
          console.log("getSlaves response data:"); //todo geert
          console.log({ ...data }); //todo geert
          userSlaves.value = data.results;
          console.log("Slaves:");
          console.log(data);
        })
        .catch(({ response }) => {
          console.log("Fout getSlaves");
        });
    };

    const clickNewAlarm = () => {
      console.log("goNewAlarm");
      alarmToEdit.value = 0;
      initAlarmEditForm.value = Math.random(); //trucje om trigger van de watch te bewerkstelligen.
      //router.push({ name: "editserie" ,params: {chartId:props.chartId}});
      //showSerieEditForm.value = true;
    };

    const clickEditAlarm = (data) => {
      console.log("goEditAlarm");
      console.log(data);
      //router.push({ name: "editserie",params: {chartId:props.chartId,serieId:data.id}});
      alarmToEdit.value = data.id;
      initAlarmEditForm.value = Math.random(); //trucje om trigger van de watch te bewerkstelligen.
    };

    const cancelAlarmEdit = () => {
      console.log("cancelEditAlarm");
      //router.push({ name: "editserie",params: {chartId:props.chartId,serieId:data.id}});
      //showSerieEditForm.value = false;
      alarmToEdit.value = 0;
    };

    const alarmUpdated = () => {
      console.log("AlarmUpdated");
      //router.push({ name: "editserie",params: {chartId:props.chartId,serieId:data.id}});
      //showSerieEditForm.value = false;
      alarmToEdit.value = 0;
      getAlarms();
    };
    const clickDeleteAlarm = (data) => {
      console.log("delete alarm:");
      console.log(data);

      ApiService.post("/device/alarmdelete", data as any)
        .then(({ data }) => {
          console.log("delete alarm response data:"); //todo geert
          console.log({ ...data }); //todo geert
          getAlarms();
        })
        .catch(({ response }) => {
          console.log("Fout alarm Delete");
        });
    };

    const clickAlarmConfiguration = (data: Mast.Alarm) => {
      console.log("clickAlarmComfiguration");
      console.log(data);
      alarmConfiguration(data.id);
    };

    const clickPublishAlarmConfig = (data: Mast.Alarm) => {
      publishAlarmConfig(data.id);
    };

    const publishAlarmConfig = (id) => {
      ApiService.get2("/device/alarmvalidateconfig?id=" + id)
        .then(({ data }) => {
          console.log("alarmvalidateconfig master response data:");
          console.log({ ...data });
          return ApiService.get2("/device/alarmpublishconfig?id=" + id);
        })
        .then(({ data }) => {
          console.log("alarmpublishconfig master response data:");
          console.log({ ...data });
          Swal.fire({
            text: "Published", //todo algemene error message
            icon: "success",
            buttonsStyling: true,
            confirmButtonText: "Ok",
          });
        })
        .catch((response) => {
          console.log("fout publishalarmConfig");
          console.log(response);
          Swal.fire({
            text: ApiService.getModelStateError(response.errors), //todo algemene error message
            icon: "error",
            buttonsStyling: true,
            confirmButtonText: "Ok",
          });
        });
    };

    const alarmConfiguration = (id) => {
      return ApiService.get2("/device/alarmconfiguration?id=" + id)
        .then(({ data }) => {
          console.log("publishalarmConfig master response data:");
          console.log(data);
          Swal.fire({
            text: JSON.stringify(data),
            icon: "info",
            buttonsStyling: true,
            confirmButtonText: "Ok",
          });
        })
        .catch((response) => {
          console.log("fout alarmConfiguration");
          console.log(response);
          Swal.fire({
            text: ApiService.getModelStateError(response.errors), //todo algemene error message
            icon: "error",
            buttonsStyling: true,
            confirmButtonText: "Ok",
          });
        });
    };

     const test = () => {
      console.log("test");
     
    };

    return {
      alarms,
      userSlaves,
      getAlarms,
      clickNewAlarm,
      clickEditAlarm,
      clickDeleteAlarm,
      alarmToEdit,
      cancelAlarmEdit,
      initAlarmEditForm,
      alarmUpdated,
      clickAlarmConfiguration,
      clickPublishAlarmConfig,
      t,
      test
    };
  },
});
