
import {
  defineComponent,
  ref,
  toRefs,
  watch,
  PropType,
  EmitsOptions,
  onMounted,
  computed,
} from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { hideModal } from "@/core/helpers/dom";
//import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import * as Mast from "@/models/MasterModel";
import ApiService from "@/core/services/ApiService";
import { useRouter } from "vue-router";
//import { Address } from "@/models/DashboardModel";
import BnPHelpers from "@/assets/ts/_utils/BnPHelpers";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "alarm-edit-form",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  props: {
    alarmId: {
      required: false,
      type: Number,
    },
    initAlarmEditForm: {
      required: true,
      type: Number,
    },

    // chartId: {
    //   required: true,
    //   type: String,
    // },
    // chartTypeId: {
    //   required: true,
    //   type: String,
    // },

    userSlaves: {
      required: true,
      type: Object as PropType<Mast.Slave[]>,
    },
  },
  emits: ["alarmUpdated", "cancelAlarmEdit"],

  setup(props, context) {
    const selectedSlaveId = ref(0);
    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const modalEditAlarmRef = ref<null | HTMLElement>(null);
    const alarmOperators = ref<Mast.GenericOption[]>([
      {} as Mast.GenericOption,
    ]);
    const router = useRouter();
    const { t} = useI18n();
    
    const getEmptyAlarm = () => {
      return {
        id: 0,
        displayName: "",
        address: {},
        addressId: 0,
        stateId: 0,
        state: {},
        acknowledgeState: {},
        operatorId: 0,
        operator: {},
        compareValue: 0,
        duration: 0,
        send: 0,
        acknowledgeStateId: 0,
      } as Mast.Alarm;
    };
    const newAlarmData = ref<Mast.Alarm>(getEmptyAlarm());

    const validationSchema = Yup.object().shape({
      displayName: Yup.string().label("displayName"),
      addressId: Yup.number().min(1).required().label("Address"),
      operator: Yup.number().label("Operator"),
      compareValue: Yup.number().required().label("CompareValue"),
      duration: Yup.number().required().label("Duration"),
      send: Yup.number().label("Send"),
    });

    const { initAlarmEditForm } = toRefs(props);

    const addressChange = (e) => {
      console.log(e);

      if (e.target.options.selectedIndex > -1) {
        newAlarmData.value.displayName =
          e.target.options[e.target.options.selectedIndex].text;
        // newAlarmData.value.unit =
        //   addressesSelectedSlave.value[e.target.options.selectedIndex].unit;

        // newAlarmData.value.decimals = 0;
        // newAlarmData.value.valueToUse = 3;
        // newAlarmData.value.valueOperator = 3;
        // newAlarmData.value.valueAbs = 0;
        // newAlarmData.value.multiplier = 1;
      }
    };

    const slaveChange = () => {
      newAlarmData.value.addressId = 0;

      // newAlarmData.value.name = "";
      // newAlarmData.value.unit = "";
      // newAlarmData.value.decimals = 0;
      // newAlarmData.value.valueToUse = 3;
      // newAlarmData.value.valueOperator = 3;
      // newAlarmData.value.valueAbs = 0;
      // newAlarmData.value.multiplier = 1;
    };

    const addressesSelectedSlave = computed(() => {
      let tmpSlaves = props.userSlaves.filter(
        (slave) => slave.id == newAlarmData.value.address.slaveId
      );
      if (tmpSlaves.length > 0) {
        console.log("returning addresses");
        console.log(tmpSlaves[0].addresses);
        return tmpSlaves[0].addresses;
      } else {
        console.log("returning addresses empty");
        return [];
      }
    });

    const submit = () => {
      console.log("Submit");
      if (!submitButtonRef.value) {
        return;
      }
      //Disable button
      submitButtonRef.value.disabled = true;
      // Activate indicator
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");

      ApiService.post("/device/alarmupdate", newAlarmData.value as any)
        .then(({ data }) => {
          console.log("submit Alarm response data:"); //todo geert
          console.log({ ...data });

          if (submitButtonRef.value) {
            submitButtonRef.value.disabled = false;
            submitButtonRef.value?.removeAttribute("data-kt-indicator");
          }
          context.emit("alarmUpdated");
          hideModal(modalEditAlarmRef.value);
        })
        .catch(({ response }) => {
          console.log("Fout Alarm Update");
        });
    };

    const getAlarmById = (id) => {
      if (!(id > 0)) {
        newAlarmData.value = getEmptyAlarm();

        console.log("newAlarmData");
        console.log(newAlarmData.value);
        return;
      }

      ApiService.get2("/device/alarmget?id=" + id)
        .then(({ data }) => {
          console.log("getalarm response data:"); //todo geert
          console.log({ ...data }); //todo geert

          data.address = { slaveId: getSlaveByAddress(data.addressId) };
          //console.log(data.slaveId);
          newAlarmData.value = data as Mast.Alarm;
        })
        .catch(({ response }) => {
          console.log("Fout getSerieById");
        });
    };

    // Todo Zoek slaveId op obv addressId omdat SlaveId niet in address zit.
    const getSlaveByAddress = (id) => {
      let curSlaveId = 0;
      props.userSlaves.forEach((slave) => {
        slave.addresses.forEach((address) => {
          if (address.id == id) curSlaveId = address.slaveId;
        });
      });
      console.log("curSlaveId");
      console.log(curSlaveId);
      return curSlaveId;
    };
    //watch(serieId, getSerieById)
    watch(initAlarmEditForm, () => {
      console.log("AlarmId: " + props.alarmId);
      getAlarmById(props.alarmId);
    });

    onMounted(() => {
      BnPHelpers.getOptions("/device/alarmOperators", alarmOperators).then(() => {
        console.log("ops");
      });
    });

    return {
      addressesSelectedSlave,
      slaveChange,
      selectedSlaveId,
      newAlarmData,
      validationSchema,
      submit,
      submitButtonRef,
      modalEditAlarmRef,
      addressChange,
      alarmOperators,
      t,
    };
  },
});
